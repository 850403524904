/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {VideoIframe, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "reasons-why-you-should-choose-hearcom-as-your-hearing-care-professionals",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#reasons-why-you-should-choose-hearcom-as-your-hearing-care-professionals",
    "aria-label": "reasons why you should choose hearcom as your hearing care professionals permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Reasons why you should choose hear.com as your hearing care professionals."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing is one of the most crucial senses that connects us to the world. It enables us to communicate, enjoy music, and stay aware of our surroundings. When hearing declines, it affects every aspect of life, making it essential to find a reliable hearing aid provider. hear.com stands out as a premier choice for hearing aids, offering advanced technology, exceptional customer service, and a personalized approach. With over 1 million served, our focus on quality has earned us top ratings — making us the most trusted name in hearing care. Here’s why you should choose and trust hear.com as your hearing aids provider."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "leading-technology-and-innovation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#leading-technology-and-innovation",
    "aria-label": "leading technology and innovation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Leading Technology and Innovation"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We don’t compromise on quality. You’ll have access to the most advanced, top-tier hearing aids created with world-leading ", React.createElement(_components.a, {
    href: "/hearing-aids/german/",
    className: "c-md-a"
  }, "German technology"), " so you can live your best life with zero limitations. hear.com is at the forefront of hearing aid technology, offering state-of-the-art devices that provide clear, natural sound. Our hearing aids are equipped with the latest features, such as Bluetooth connectivity, rechargeable batteries, and advanced noise reduction. These innovations ensure that users experience unparalleled sound quality in various environments, from noisy restaurants to quiet living rooms. The seamless integration with smartphones and other devices allows users to customize their hearing experience and control their hearing aids discreetly."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "expert-guidance-and-support",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#expert-guidance-and-support",
    "aria-label": "expert guidance and support permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Expert Guidance and Support"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Navigating the world of hearing aids can be daunting, but hear.com simplifies the process with expert guidance and support. We’ve handpicked the top 2% of America’s hearing professionals who are dedicated to guaranteeing your best, personalized results. Our team of audiologists and hearing specialists are dedicated to helping you find the perfect solution for your hearing needs. We start with a comprehensive hearing assessment to understand your unique requirements and preferences. Based on this assessment, we recommend hearing aids that best match your lifestyle, hearing loss severity, and budget. Our specialists are always available to answer questions and provide support, ensuring you feel confident and informed throughout your journey."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "personalized-hearing-solutions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#personalized-hearing-solutions",
    "aria-label": "personalized hearing solutions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Personalized Hearing Solutions"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we believe that one size does not fit all when it comes to hearing aids. That’s why we offer a wide range of devices from leading manufacturers, each designed to cater to different levels of hearing loss and user preferences. Whether you need a discreet, in-the-ear model or a powerful behind-the-ear device, we have options to suit every need. Our personalized approach ensures that you receive a hearing aid that not only improves your hearing but also fits comfortably and seamlessly into your daily life."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "exceptional-customer-experience",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#exceptional-customer-experience",
    "aria-label": "exceptional customer experience permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Exceptional Customer Experience"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Customer satisfaction is at the heart of hear.com’s mission. We strive to provide an exceptional experience from the moment you contact us. Our streamlined process makes it easy to get started: simply schedule a free consultation, undergo a thorough hearing test, and receive expert recommendations tailored to you. Once you have your hearing aids, we continue to support you with regular check-ins, adjustments, and maintenance services. Our goal is to make your transition to using hearing aids as smooth and hassle-free as possible."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "risk-free-trial-and-warranty",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#risk-free-trial-and-warranty",
    "aria-label": "risk free trial and warranty permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Risk-Free Trial and Warranty"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Choosing the right hearing aid is a significant decision, and we want you to feel completely confident in your choice. That’s why hear.com offers a risk-free trial period, allowing you to test your hearing aids in real-world conditions before making a final commitment. During this period, you can evaluate the performance, comfort, and effectiveness of the devices. If you’re not completely satisfied, we’ll work with you to find a better solution or offer a full refund. Additionally, our hearing aids come with comprehensive warranties, providing peace of mind and protection for your investment."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "positive-impact-on-quality-of-life",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#positive-impact-on-quality-of-life",
    "aria-label": "positive impact on quality of life permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Positive Impact on Quality of Life"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Investing in a hearing aid from hear.com can significantly enhance your quality of life. Improved hearing can lead to better communication with loved ones, increased confidence in social situations, and a greater sense of independence. Many of our customers report a renewed enjoyment of activities they once found challenging, such as watching TV, attending events, or participating in group conversations. By choosing hear.com, you’re not just investing in a hearing aid; you’re investing in a better, more connected life."), "\n", React.createElement(VideoIframe, {
    title: "Why choose hear.com",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/eihPzvlUn_s?feature=oembed/"
  }), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
